import IconButton from "@mui/material/IconButton";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { FC, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useStoreActions } from "../../hooks";
import { Status } from "../../models/status";
import TextField from "@mui/material/TextField";
import { IProjectSummaryDto } from "../../interfaces/DTO/IProjectSummaryDto";
import DotsMenu from "./DotsMenu";
import { LoadingButton } from "@mui/lab";
import EditDialog, { editDialogRef } from "../../pages/Configurator/DesignSummary/Edit/EditDialog";
import useProjectHandler from "../../hooks/useProjectHandler";

interface IThreeDotsMenuProps {
  data: IProjectSummaryDto;
  enableNameEdit?: boolean;
}

const ThreeDotsMenu: FC<IThreeDotsMenuProps> = ({ data, enableNameEdit = false }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { handleOpenProject, handleDeleteProject } = useProjectHandler();

  const { thunkOpenSnackBar, thunkCloseConfirm } = useStoreActions((actions) => actions.app);

  const { thunkCloneProject } = useStoreActions((actions) => actions.designs);
  const { thunkGetProjectsSummary } = useStoreActions((actions) => actions.designs);

  const [, setDeleteOpenAlertDialog] = useState<boolean>(false);
  const [openDuplicateAlertDialog, setDuplicateAlertDialog] = useState<boolean>(false);
  const [duplicateProjectName, setDuplicateProjectName] = useState<string>("");
  const [projectToBeDuplicated, setProjectToBeDuplicated] = useState<IProjectSummaryDto | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState<string>("");

  const handleOpenEditProjectName = () => {
    setProjectName(data.name);
    editDialogRef.current?.open();
  };

  const handleMoreOptionsClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAlertDialog = () => {
    setDeleteOpenAlertDialog(false);
    setDuplicateAlertDialog(false);
  };

  const handleDuplicate = (project: IProjectSummaryDto) => {
    setProjectToBeDuplicated(project);
    setDuplicateAlertDialog(true);
  };

  const handleSubmitDuplicateAlertDialog = async () => {
    if (projectToBeDuplicated != null) {
      const duplicatedProjectName = duplicateProjectName.trim();
      if (duplicatedProjectName.length === 0) {
        thunkOpenSnackBar({
          message: t("DuplicateProjectDialog_EnterValidProjectName_Message"),
          severity: "warning",
        });

        return;
      }
      if (duplicatedProjectName.length > 50) {
        thunkOpenSnackBar({
          message: t("DuplicateProjectDialog_MaxLengthProjectName_Message"),
          severity: "warning",
        });

        return;
      }

      setLoading(true);

      const response = await thunkCloneProject({ projectId: data.id, name: duplicatedProjectName });

      if (response.status === Status.FAILED) {
        thunkOpenSnackBar({
          message: t(response.message),
          severity: "error",
        });
        setLoading(false);
        return;
      }

      setDuplicateAlertDialog(false);
      thunkCloseConfirm();
      setLoading(false);
      thunkGetProjectsSummary();
    }
  };

  return (
    <>
      <IconButton onClick={(e) => handleMoreOptionsClick(e)}>
        <MoreVertOutlinedIcon sx={{ color: "#000" }} />
      </IconButton>

      <DotsMenu
        data={data}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleDeleteProject={handleDeleteProject}
        handleDuplicate={handleDuplicate}
        handleEdit={handleOpenProject}
        enableNameEdit={enableNameEdit}
        handleOpenEditProjectName={handleOpenEditProjectName}
      />

      <Dialog
        open={openDuplicateAlertDialog}
        onClose={handleCloseAlertDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "480px",
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{t("duplicateProject")}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("DuplicateProjectDialog_Title")}</DialogContentText>
          <TextField
            inputProps={{
              style: {
                padding: 5,
              },
            }}
            sx={{
              margin: "20px 0 10px 0",
              width: "100%",
            }}
            disabled={isLoading}
            value={duplicateProjectName}
            onChange={(e) => setDuplicateProjectName(e.target.value)}
            helperText={
              duplicateProjectName.trim().length > 50 ? t("DuplicateProjectDialog_InputProjectName_HelperText") : ""
            }
            error={duplicateProjectName.trim().length > 50 ? true : false}
          />
        </DialogContent>

        <DialogActions style={{ marginRight: "15px" }}>
          <Button
            variant="text"
            onClick={handleCloseAlertDialog}
          >
            {t("cancel")}
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={handleSubmitDuplicateAlertDialog}
            autoFocus
            disabled={duplicateProjectName.trim().length > 50 || duplicateProjectName.trim().length === 0}
          >
            {t("duplicate")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <EditDialog
        ref={editDialogRef}
        name={projectName}
        projectId={data.id}
        setProjectName={setProjectName}
      />
    </>
  );
};

export default ThreeDotsMenu;
