export const ENGLISH = "en-UK";
export const DEUTSCH = "de-DE";

export const COUNTRIES_CODES = {
  Switzerland: 21,
  Germany: 30,
};

export const LANGUAGES_CODES = {
  Deutsch: 2,
};
