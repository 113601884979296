import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useBackdrop } from "./backdrop";
import { useStoreActions, useStoreState } from "../hooks";
import { IProductDesignResponse } from "../interfaces/Project/Response/ProductDesignResponse";
import { Status } from "../models/status";
import { IProjectSummaryDto } from "../interfaces/DTO/IProjectSummaryDto";

const useProjectHandler = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openBackdrop, closeBackdrop } = useBackdrop();

  const { designs } = useStoreState((state) => state.designs.data);
  const { thunkOpenSnackBar, thunkOpenConfirm, thunkCloseConfirm } = useStoreActions((actions) => actions.app);
  const {
    setCurrentDesignId,
    setCurrentProjectId,
    setUserDesignDataFromProductDesignResponse,
    thunkGetProductDesignDataByProjectId,
    thunkDeleteProject,
    setUserDesignDataFromDesign,
  } = useStoreActions((actions) => actions.designs);

  const handleOpenProject = async (projectId: string) => {
    openBackdrop({ title: t("dashboard.div_loading").toUpperCase() });
    const response = await thunkGetProductDesignDataByProjectId(projectId);

    if (response.status === Status.FAILED) {
      thunkOpenSnackBar({
        message: t("recent_projects.message_error.get_product_design_by_userId"),
        severity: "error",
      });
      closeBackdrop();
      return;
    }

    setCurrentProjectId(projectId);
    const productDesignsFromProject = response.data as IProductDesignResponse[];
    const hasMoreThanOne = productDesignsFromProject.length > 1;
    const empty = productDesignsFromProject.length === 0;

    if (hasMoreThanOne) {
      navigate("/design-summary");
      closeBackdrop();
      return;
    }

    if (empty) {
      closeBackdrop();

      if (!designs.length) {
        thunkOpenSnackBar({
          message: t("configurator_welcome.designs_not_available"),
          severity: "error",
        });
        return;
      }

      setUserDesignDataFromDesign(designs[0]);
      navigate("/start-configurator", { state: { isNewProject: false } });
      return;
    }

    const prodDesign = hasMoreThanOne
      ? productDesignsFromProject.find((p) => p.project.id === projectId)
      : productDesignsFromProject[0];

    setCurrentDesignId(prodDesign?.id ?? null);
    setUserDesignDataFromProductDesignResponse(prodDesign!);
    closeBackdrop();

    navigate(prodDesign?.pathname ?? "/", {
      state: { isNewProject: false, isDesignSelected: prodDesign !== undefined },
    });
  };

  const handleDeleteProject = (project: IProjectSummaryDto) => {
    thunkOpenConfirm({
      title: t("DeleteProject_ConfirmDialog_Title"),
      message: t("DeleteProject_ConfirmDialog_Message"),
      onDismiss: {
        text: t("DeleteProject_ConfirmDialog_DismissButton_Text"),
      },
      onConfirm: {
        text: t("DeleteProject_ConfirmDialog_ConfirmButton_Text"),
        onClick: () => handleSubmitDelete(project),
      },
      useLoading: true,
      highlightCancel: true,
    });
  };

  const handleSubmitDelete = async (project: IProjectSummaryDto) => {
    const res = await thunkDeleteProject(project.id);

    if (res === Status.COMPLETED) {
      thunkOpenSnackBar({
        message: t("DeleteProject_ConfirmDialog_SuccessMessage").replace("[PROJECT_NAME]", project.name),
        severity: "success",
      });
      thunkCloseConfirm();
      return;
    }

    thunkOpenSnackBar({
      message: t(res.message),
      severity: "error",
    });

    thunkCloseConfirm();
  };

  return { handleOpenProject, handleDeleteProject };
};

export default useProjectHandler;
