export const PROJECTS_TABLE = {
  NAME: "name",
  QUANTITY: "productDesignsQuantity",
  CREATED_AT: "createdAt",
  LAST_MODIFIED: "lastModified",
  STATUS: "status",
  EMPTY: "empty",
};

export const FAVORITE_DESIGNS_TABLE = {
  PREVIEW: "preview",
  DESCRIPTION: "description",
  COMMENT: "comment",
  CREATED_AT: "createdAt",
  EMPTY: "empty",
};
