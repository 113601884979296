import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ThreeDotsMenu from "../../../components/ThreeDotsMenu/ThreeDotsMenu";
import { useStoreState } from "../../../hooks";
import { IProjectSummaryDto } from "../../../interfaces/DTO/IProjectSummaryDto";
import "./RecentProjects.css";
import SeeAllButton from "./SeeAllButton";
import ProjectLink from "./ProjectLink";
import { BASIC_GLOBAL_DATE_FORMAT } from "../../../constants/DateFormatTypes";
import { PROJECTS_PATH } from "../../../constants/Paths";
import useProjectHandler from "../../../hooks/useProjectHandler";

const RecentProjects: FC<{}> = (props) => {
  const { t } = useTranslation();

  const { handleOpenProject } = useProjectHandler();
  const { currentUser } = useStoreState((state) => state.auth);
  const { projectsSummary } = useStoreState((state) => state.designs.data);
  const [availableProjectsSummary, setAvailableProjectsSummary] = useState<IProjectSummaryDto[]>([]);

  useEffect(() => {
    setAvailableProjectsSummary(projectsSummary.filter((x) => x.countryId == currentUser?.countryId));
  }, [projectsSummary]);

  return (
    <>
      <div className="recentProjects">
        <div className="recentProjects__headerContainer">
          <Typography
            variant="h3"
            fontSize="18px"
            fontWeight={500}
            p="30px 30px 16px 30px"
            m={0}
          >
            {t("recentProjects")}
          </Typography>
          <SeeAllButton pathname={PROJECTS_PATH} />
        </div>
        <div className="recentProjects__table">
          {(availableProjectsSummary != null && availableProjectsSummary.length > 0 && (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">{t("projectName")}</TableCell>
                    <TableCell align="left">{t("numberOfProducts")}</TableCell>
                    <TableCell align="right">{t("lastModified")}</TableCell>
                    <TableCell align="right">{t("table.head_cell.label_action")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {availableProjectsSummary
                    .sort((p1, p2) => (p1.createdAt < p2.createdAt ? 1 : -1))
                    .slice(0, 3)
                    .map((project) => (
                      <TableRow key={project.id}>
                        <TableCell align="left">
                          <ProjectLink
                            projectId={project.id}
                            projectName={project.name}
                            handleOpenProject={() => handleOpenProject(project.id)}
                          />
                        </TableCell>
                        <TableCell align="left">{project.productDesignsQuantity}</TableCell>
                        <TableCell align="right">
                          {moment(project.createdAt).format(BASIC_GLOBAL_DATE_FORMAT) ?? "N/A"}
                        </TableCell>
                        <TableCell align="right">
                          <ThreeDotsMenu data={project} />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          )) || (
            <div className="recentProjects__listNotification">
              <Typography className="recentProjects__notification">{t("youHaveNotCreatedOneYet")}</Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RecentProjects;
