import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FTSTheme } from "../../App";
import ThreeDotsMenuFavDesigns from "../../components/ThreeDotsMenuFavDesigns/ThreeDotsMenuFavDesigns";
import { useStoreActions, useStoreState } from "../../hooks";
import { IProjectSummaryDto } from "../../interfaces/DTO/IProjectSummaryDto";
import { IProductFavoriteDesignResponse } from "../../interfaces/Project/Response/ProductDesignResponse";
import "./FavoriteDesigns.css";
import FitText from "../../components/FitText/FitText";
import { NUMBER_OF_SINGLE_COVER, PAGE_INITIAL_INDEX, ROWS_PER_PAGE } from "../../constants/NumberValues";
import FavoriteDesignsPreview, { favoriteDesignsPreviewRef } from "./FavoriteDesignsPreview";
import { BASIC_GLOBAL_DATE_FORMAT } from "../../constants/DateFormatTypes";
import { FAVORITE_DESIGNS_TABLE } from "../../constants/TableHeads";

interface IProps {
  favoriteDesigns: IProductFavoriteDesignResponse[] | null;
  checked: string[];
  setChecked: Dispatch<SetStateAction<string[]>>;
  available: boolean;
}

interface IHeaderCell {
  id: string;
  label: string;
  width: string;
  sortable: boolean;
}

const FavoriteDesignsTable: React.FC<IProps> = ({ favoriteDesigns, available }) => {
  const { t } = useTranslation();
  const ftsTheme = useTheme() as FTSTheme;

  const [page, setPage] = useState(PAGE_INITIAL_INDEX);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [sortedFavoriteDesigns, setSortedFavoriteDesigns] = useState<IProductFavoriteDesignResponse[]>(
    favoriteDesigns || []
  );
  const { favoriteDesignData } = useStoreState((state) => state.designs.data);

  const { thunkGetFavoriteDesignById, setProductDesignPreview, setUserDesignDataFromProductDesignResponse } =
    useStoreActions((actions) => actions.designs);

  useEffect(() => {
    favoriteDesigns && setSortedFavoriteDesigns(favoriteDesigns);
  }, [favoriteDesigns]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(PAGE_INITIAL_INDEX);
  };

  const headerCells: readonly IHeaderCell[] = [
    {
      id: FAVORITE_DESIGNS_TABLE.PREVIEW,
      label: "favoriteDesigns.table.header.preview",
      width: "",
      sortable: false,
    },
    {
      id: FAVORITE_DESIGNS_TABLE.DESCRIPTION,
      label: "favoriteDesigns.table.header.description",
      width: "",
      sortable: false,
    },
    {
      id: FAVORITE_DESIGNS_TABLE.COMMENT,
      label: "favoriteDesigns.table.header.comment",
      width: "",
      sortable: false,
    },
    {
      id: FAVORITE_DESIGNS_TABLE.CREATED_AT,
      label: "favoriteDesigns.table.header.create_date",
      width: "",
      sortable: true,
    },
    {
      id: FAVORITE_DESIGNS_TABLE.EMPTY,
      label: "",
      width: "",
      sortable: false,
    },
  ];

  type Order = "asc" | "desc";
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>(FAVORITE_DESIGNS_TABLE.CREATED_AT);

  const handleRequestSort = (property: keyof IProjectSummaryDto) => {
    const isAsc = orderBy === property && order === "asc";
    const sortedDesigns =
      favoriteDesigns &&
      [...favoriteDesigns].sort((a, b) => {
        const valueA = a[property] || "";
        const valueB = b[property] || "";
        if (valueA < valueB) {
          return isAsc ? -1 : 1;
        }
        if (valueA > valueB) {
          return isAsc ? 1 : -1;
        }
        return 0;
      });
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortedFavoriteDesigns(sortedDesigns || []);
  };

  const handleOpenDetails = async (favoriteDesignId: string, numberOfCovers: number | undefined) => {
    let designToSet;

    if (favoriteDesignData?.favoriteDesignId === favoriteDesignId) {
      designToSet = favoriteDesignData;
    } else {
      try {
        designToSet = await thunkGetFavoriteDesignById(favoriteDesignId);
      } catch (error) {
        console.error("Failed to fetch design:", error);
        return;
      }
    }

    if (designToSet) {
      setUserDesignDataFromProductDesignResponse(designToSet);
      setProductDesignPreview(designToSet);
      favoriteDesignsPreviewRef.current?.open();
    }
  };

  return (
    <>
      <TableContainer
        sx={{
          "& .MuiTable-root": {
            border: "1px solid #DADADA",
            borderRadius: 4,
          },
        }}
      >
        <Table className="designSummary__table">
          <TableHead className={`projects__${ftsTheme.name}_table_head`}>
            <TableRow>
              {headerCells.map((headerCell: IHeaderCell) => {
                return (
                  <TableCell
                    className={`projects__table_header_cell`}
                    width={headerCell.width}
                    key={headerCell.id}
                  >
                    {headerCell.sortable ? (
                      <TableSortLabel
                        active={orderBy === headerCell.id}
                        direction={orderBy === headerCell.id ? order : "asc"}
                        onClick={() => handleRequestSort(headerCell.id as keyof IProjectSummaryDto)}
                      >
                        {t(headerCell.label)}
                      </TableSortLabel>
                    ) : (
                      <>{t(headerCell.label)}</>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {!favoriteDesigns?.length && (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="recentProjects__listNotification">
                    <Typography sx={{ marginTop: "15px" }}>{t("favoriteDesigns_emptyList")}</Typography>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {sortedFavoriteDesigns.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((favoriteDesign) => {
              return (
                <TableRow key={favoriteDesign.favoriteDesignId}>
                  <TableCell className="projects__table_row_cell">
                    <div className="center-content">
                      <div
                        className="thumb"
                        onClick={() => handleOpenDetails(favoriteDesign.favoriteDesignId, NUMBER_OF_SINGLE_COVER)}
                      >
                        {favoriteDesign?.coverImage && (
                          <img
                            src={favoriteDesign.coverImage}
                            alt={`${favoriteDesign.name}, ${t(`insertcolors.${favoriteDesign.colorCode}`)}`}
                          />
                        )}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className="projects__table_row_cell">{`${favoriteDesign.name}, ${t(
                    `insertcolors.${favoriteDesign.colorCode}`
                  )}`}</TableCell>
                  <TableCell className="projects__table_row_cell">{favoriteDesign.comment}</TableCell>
                  <TableCell className="projects__table_row_cell">
                    {moment(favoriteDesign.createdAt).format(BASIC_GLOBAL_DATE_FORMAT)}
                  </TableCell>
                  <TableCell>
                    {available && (
                      <>
                        <Button
                          onClick={() => handleOpenDetails(favoriteDesign.favoriteDesignId, NUMBER_OF_SINGLE_COVER)}
                          sx={{
                            width: 124,
                            height: 40,
                            borderColor: "#DBDBDB",
                          }}
                          variant="outlined"
                          color="primary"
                        >
                          <FitText sx={{ fontSize: 14, fontWeight: 500, color: ftsTheme.icon.color }}>
                            {t("DesignSummary_SeeDetails")}
                          </FitText>
                        </Button>
                        <Box sx={{ paddingLeft: "24px", display: "initial" }}>
                          <ThreeDotsMenuFavDesigns favoriteDesignId={favoriteDesign.favoriteDesignId} />
                        </Box>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {favoriteDesigns && favoriteDesigns.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[ROWS_PER_PAGE]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          component="div"
          count={favoriteDesigns.length}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
      <FavoriteDesignsPreview
        displayPreviewTabs
        ref={favoriteDesignsPreviewRef}
      />
    </>
  );
};

export default FavoriteDesignsTable;
