import React, { useEffect } from "react";
import { Suspense } from "react";
import store from "./store";
import { StoreProvider } from "easy-peasy";
import "./index.css";
import App from "./App";
import Spinner from "./components/Spinner/Spinner";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createRoot } from "react-dom/client";
import i18next from "i18next";
import { useStoreState } from "./hooks";
import { ABB } from "./constants/Theme";
import { DEUTSCH, ENGLISH } from "./constants/Languages";

const container = document.getElementById("root");
const root = createRoot(container!);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  environment: process.env.REACT_APP_SENTRY_ENV ?? "Local",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: process.env.REACT_APP_SENTRY_ENV !== "Local",
});

const InitializeI18nComponent = () => {
  const currentUser = useStoreState((state) => state.auth.currentUser);
  const languages = useStoreState((state) => state.languages.languages);

  const userLanguageId = currentUser?.languageId;
  const userLanguage = languages.find((x) => x.id === userLanguageId);
  const theme = process.env.REACT_APP_THEME;

  useEffect(() => {
    const themeInitialLang = theme === ABB ? ENGLISH : DEUTSCH;
    const userLang = userLanguage?.code || themeInitialLang;

    i18next.init({
      lng: userLang,
      returnNull: false,
    });
  }, [theme, userLanguage]);

  return null;
};

export default InitializeI18nComponent;

if (localStorage.version !== process.env.REACT_APP_VERSION) {
  localStorage.clear();
  localStorage.setItem("version", process.env.REACT_APP_VERSION || "");
}

root.render(
  <React.StrictMode>
    <Suspense fallback={<Spinner />}>
      <StoreProvider store={store}>
        <InitializeI18nComponent />
        <App />
      </StoreProvider>
    </Suspense>
  </React.StrictMode>
);
